import styled from 'styled-components';
import {device} from '../Common/device'
import {SocialFacebook} from '@styled-icons/typicons/SocialFacebook'
import {SocialTwitter} from '@styled-icons/typicons/SocialTwitter'
import {SocialLinkedin} from '@styled-icons/typicons/SocialLinkedin'
import {SocialInstagram} from '@styled-icons/typicons/SocialInstagram'

export const FooterSection = styled.footer`
    background:#f9fafc;
`;

export const FooterInner = styled.div`
    padding:60px 0px 10px;
    background-size:100%;

    position:relative;

    @media ${device.tablet} {
        padding: 60px 10px 10px;
    }
`;

export const FooterCol = styled.div`
    padding:20px 0px;
    text-align:left;

    ul{
        margin-left:0px;
        margin-bottom:0px;
    }
    a{
        text-decoration:none;
        color:#595959;
        :hover{
            text-decoration:underline;
        }
    }

    h4{
        margin-bottom:25px;
        text-transform:uppercase;
        text-align:left;
    }

    @media ${device.tablet} {
        padding:20px 0px;
    }

    img{
        max-width: 300px;

        @media ${device.tablet} {
            max-width: 160px;
        }
    }
    p{
        margin-bottom:5px;
    }
`;



export const FooterMenu = styled.a`
    color:#595959;
    text-decoration:none;
    font-size:15px;
    :hover
    {
        text-decoration:underline;
    }
`;

export const FooterSocialWrapper = styled.div`
    float:right;
`;

export const FooterSocial = styled.a`
    margin-right:10px;
`;

export const FooterAddWrapper = styled.div`
    display:flex;
    align-items:top;
`;

export const FooterAddLeft = styled.div`
    width:14px;
    flex-shrink:0;
    img{
        margin-bottom: 0px;
        width:14px;
    }
`;

export const FooterAddRight = styled.div`
    margin-left:10px;
    p{
        color:#595959;
        margin-bottom:0px;
    }
`;



export const UnorderedList = styled.ul`
    list-style:none;
    margin:0;

    @media ${device.tablet} {
        padding: 0px 10px;
    }
`;

export const FooterAnchorLayout = styled.li`
    margin-right:20px;
    margin-bottom:20px;
    display: inline-flex;

    a{
        text-decoration:none;
        color:#595959;
        font-size:16px;
    }
`;

export const BottomFooterWrapper = styled.section`
    width:100%;
    padding:20px 0px;
    text-align:center;
    background-size:100%;
`;

export const BottomFooterPara = styled.p`
    margin-bottom:0px;
    color:#666666;
    padding:10px 0px;
    font-size:15px;
    line-height: 15px;
    float:left;
`;

export const BottomLink = styled.a`
    color:#666666;
    font-size:15px;
    text-decoration:none;
    :hover{
        text-decoration:underline;
    }
`;

export const InstaIcon = styled(SocialInstagram)`
    width: 32px;
    color: #ed008d;
    background: rgba(237, 0, 141, .2);
    padding: 6px;
    border-radius:100%;
    transition:all .5s;
    :hover{
        background:#ed008d;
        color:#fff;
    }
`;
export const LinkedinIcon = styled(SocialLinkedin)`
    width: 32px;
    color: #3b5999;
    background: rgba(59, 89, 153, .2);
    padding: 6px;
    border-radius:100%;
    transition:all .5s;
    :hover{
        background:#3b5999;
        color:#fff;
    }
`;
export const TwitterIcon = styled(SocialTwitter)`
    width: 32px;
    color: #00adee;
    background: rgba(0, 173, 238, .2);
    border-radius:100%;
    padding: 6px;
    transition:all .5s;
    :hover{
        background:#00adee;
        color:#fff;
    }
`;
export const FbIcon = styled(SocialFacebook)`
    width: 32px;
    color: #3b5999;
    background: rgba(59, 89, 153, .2);
    border-radius:100%;
    padding: 6px;
    transition:all .5s;
    :hover{
        background:#3b5999;
        color:#fff;
    }
`;
