import React,{Component} from 'react';
import {GallerySection,GalleryOuterWrapper,HeadingLayout,Heading,GalleryWrapper,ImageHolder
} from './gallery.style';
import {Container,Row,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { GatsbyImage } from "gatsby-plugin-image";
import { StaticQuery, graphql } from 'gatsby';

class Gallery extends Component{
    constructor(props) {
        super(props);
        this.state = {
            isopenDialogBox: false,
            lightBoxImages:[],
            photoIndex: 0,
        }
    }

    openDialogBox(imageArray,idx)
    {
        this.props.openLightBox(imageArray,idx);
    }

    render() {
        return (
            <GallerySection id="galleryContainer">
                <Container>
                    <GalleryOuterWrapper>
                        <HeadingLayout>
                            <Heading>
                            {this.props.hospitalJson.Gallery.Heading}
                            </Heading>
                            <img src={this.props.hospitalJson.Gallery.Border}  alt=""/>
                        </HeadingLayout>
                        <GalleryWrapper>
                            <Row>
                            {
                                this.props.hospitalJson.Gallery.GalleryData.map((galleryItem, idx)=>{
                                    return (
                                        <Col md={4}>
                                            <ImageHolder onClick={this.openDialogBox.bind(this,this.props.hospitalJson.Gallery.GalleryData,idx)}>
                                                <GatsbyImage
                                                    image={galleryItem.Img.childImageSharp.gatsbyImageData}
                                                    alt=""
                                                    className="galleryImg" />
                                            </ImageHolder>
                                        </Col>
                                    );
                                })
                            }


                            </Row>
                        </GalleryWrapper>
                    </GalleryOuterWrapper>
                </Container>
            </GallerySection>
        );
    }
}

const GalleryPageSection = (props) => (
    <StaticQuery
        query={graphql`{
  hospitalJson {
    Gallery {
      Heading
      Border
      GalleryData {
        Img {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
          publicURL
        }
      }
    }
  }
}
`}
        render={(data) => (
            <Gallery
                hospitalJson={data.hospitalJson}
                {...props}
            />
        )}
    />
    )
export default GalleryPageSection;