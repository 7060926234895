import React,{Component} from 'react';
import {TestimonialsSection,HeadingLayout,Heading,SliderOuterLayout,LeftArrowLayout,LeftArrow,
    TestimonialsOuterWrapper,SliderInnerLayout,TestimonialLayout,QuotesTop,
    QuotesHolder,QuotesBottom,AuthorLayout,TextLayout,
    RightArrowLayout,RightArrow
} from './testimonials.style';
import {Container} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { StaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class TestimonialsPage extends Component{

    constructor(props) {
        super(props);
        this.next = this.next.bind(this);
        this.previous = this.previous.bind(this);
    }

    next() {
        this.slider.slickNext();
    }

    previous() {
        this.slider.slickPrev();
    }

    render() {
        var settings = {
            dots: false,
            arrows:false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
        };

        return (
            <TestimonialsSection id="testimonialsContainer">
                <HeadingLayout>
                    <Heading>
                    {this.props.hospitalJson.Testimonials.Heading}
                    </Heading>
                    <img src={this.props.hospitalJson.Testimonials.Border}  alt=""/>
                </HeadingLayout>

                <SliderOuterLayout>
                    <LeftArrowLayout>
                        <LeftArrow onClick={this.previous}/>
                    </LeftArrowLayout>
                    <Container>
                        <TestimonialsOuterWrapper>
                            <Slider ref={c => (this.slider = c)} {...settings}>
                                {
                                    this.props.hospitalJson.Testimonials.Slider.map((testimonial, idx)=>{
                                        return (
                                            <SliderInnerLayout>
                                                <TestimonialLayout>
                                                    <QuotesTop />
                                                    <p>
                                                    {testimonial.Testimonial}
                                                    </p>
                                                    <QuotesHolder>
                                                        <QuotesBottom />
                                                    </QuotesHolder>
                                                    <AuthorLayout>
                                                        <GatsbyImage
                                                            image={testimonial.ProPic.childImageSharp.gatsbyImageData}
                                                            className="ProPic"
                                                            alt="" />
                                                        <TextLayout>
                                                            <h6>
                                                            {testimonial.Name}
                                                            </h6>
                                                            <p>
                                                            {testimonial.Designation}
                                                            </p>
                                                        </TextLayout>
                                                    </AuthorLayout>
                                                </TestimonialLayout>
                                            </SliderInnerLayout>
                                        );
                                    })
                                }
                            </Slider>
                        </TestimonialsOuterWrapper>
                    </Container>

                    <RightArrowLayout>
                        <RightArrow onClick={this.next}/>
                    </RightArrowLayout>

                </SliderOuterLayout>
            </TestimonialsSection>
        );
    }
}

const TestimonialsPageSection = () => (
    <StaticQuery
        query={graphql`{
  hospitalJson {
    Testimonials {
      Heading
      Border
      Slider {
        Testimonial
        ProPic {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        Name
        Designation
      }
    }
  }
}
`}
        render={(data) => (
            <TestimonialsPage hospitalJson={data.hospitalJson}/>
        )}
    />
  )
  export default TestimonialsPageSection;
