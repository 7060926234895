import styled from 'styled-components';
import {device} from '../Common/device';

export const ServicesSection = styled.section`

`;
export const FeaturesWrapper = styled.div`
    padding:100px 0px 70px;
    border-bottom:1px solid #ebebeb;

    @media ${device.tablet} {
        padding:80px 10px 50px;
    }
`;

export const FeaturesLayout = styled.div`
    background:${props => props.bg};
    padding:30px;
    margin-bottom:30px;


    h3{
        color:#fff;
        text-align:left;
        line-height:1;
        margin-bottom:10px;
    }
    p{
        color:#fff;
        margin-bottom:0px;
    }
`;

export const Img = styled.img`
    height:60px;
    margin-bottom:20px;
`;
