import styled from 'styled-components';
import {device} from '../Common/device';

export const Commonbtn = styled.a`
    background: #1565c0;
    color: #fff;
    padding: 8px 40px;
    text-decoration:none;
    border-radius: 20px;
    display:inline-block;
    text-transform:uppercase;
    cursor:pointer;

    :hover{
        background:#000000cc;
        color:#fff;
    }

    @media ${device.mobileXL} {
        padding: 8px 30px;
    }
`;

export const SectionHeading = styled.h2`
    text-align:center;
    line-height:1;
    margin-bottom:10px;
`;
