import React from 'react';
import {EmergencySection,EmergencyLayout,CallNowBtn
} from './emergency.style';
import {Container,Row,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useStaticQuery, graphql } from 'gatsby';

const Emergency = () => {
    const JSONData  = useStaticQuery(graphql`
        query {
                hospitalJson{
                    Emergency{
                        EmergencyHeading
                        EmergencyContact
                        EmergencyBtn
                    }
                }
            }
    `);
        return (
        <EmergencySection id="emergencyContainer">
            <Container>
                <Row>
                    <Col md={6}>
                        <EmergencyLayout>
                            <h6>
                            {JSONData.hospitalJson.Emergency.EmergencyHeading}
                            </h6>
                            <h1>
                            {JSONData.hospitalJson.Emergency.EmergencyContact}
                            </h1>
                            <CallNowBtn>
                            {JSONData.hospitalJson.Emergency.EmergencyBtn}
                            </CallNowBtn>
                        </EmergencyLayout>
                    </Col>
                </Row>
            </Container>
        </EmergencySection>
    );
}
export default Emergency;
