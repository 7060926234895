import React from 'react';
import {AboutSection,CustomRow,TextLayout,HeadingLayout,Heading,Border,
    CheckCircleIcon,ContactUsBtn,ImageHolder
} from './about.style';
import {Container,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from 'gatsby';

const About = () => {
    const JSONData  = useStaticQuery(graphql`{
  hospitalJson {
    About {
      Heading
      Border
      Description1
      ListText1
      ListText2
      ContactUsBtn
      Img {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
    }
  }
}
`);
        return (
            <AboutSection id="aboutContainer">
                <Container>
                    <CustomRow>
                        <Col md={12} lg={6}>
                            <ImageHolder>
                                <GatsbyImage
                                    image={JSONData.hospitalJson.About.Img.childImageSharp.gatsbyImageData}
                                    alt="" />
                            </ImageHolder>
                        </Col>
                        <Col md={12} lg={6}>
                            <TextLayout>
                                <HeadingLayout>
                                    <Heading>
                                    {JSONData.hospitalJson.About.Heading}
                                    </Heading>
                                    <Border src={JSONData.hospitalJson.About.Border} alt=""/>
                                </HeadingLayout>
                                <p>
                                {JSONData.hospitalJson.About.Description1}
                                </p>
                                <ul>
                                    <li>
                                        <CheckCircleIcon />
                                        <p>{JSONData.hospitalJson.About.ListText1}</p>
                                    </li>
                                    <li>
                                        <CheckCircleIcon />
                                        <p>{JSONData.hospitalJson.About.ListText2}</p>
                                    </li>
                                </ul>
                                <ContactUsBtn>
                                {JSONData.hospitalJson.About.ContactUsBtn}
                                </ContactUsBtn>
                            </TextLayout>
                        </Col>
                    </CustomRow>
                </Container>
            </AboutSection>
        );
}

export default About;
