import React,{Component} from "react";
import {NavbarCustom,NavbarToggleCustom,BrandLogo} from "./headermenubs.style"
import {
    Container,Nav,Navbar
} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { StaticQuery, graphql } from 'gatsby';
import ScrollSpy from 'react-scrollspy'
import AnchorLink from 'react-anchor-link-smooth-scroll';

class Headermenu extends Component{
    constructor(props){
        super(props);
        this.state = { 
            stickyClass:'top',
        };
    }

    componentDidMount(){
        window.addEventListener('scroll', () => {
            let stickyClass = 'topSticky';
            let scrollPos = window.scrollY;

            if(scrollPos < 100){
                stickyClass = 'top';
            }

            if(this.state.stickyClass !== stickyClass)
            {
                this.setState({ stickyClass });
            }
                
        });
    }

    componentWillUnmount() { 
        window.removeEventListener('scroll'); 
    } 

    render(){
        return(
            <NavbarCustom 
                expand="lg" fixed="top" 
                isSticky={this.state.stickyClass==="topSticky"?true:false}>
                <Container>
                    <Navbar.Brand href="/">
                        <BrandLogo src={this.state.stickyClass==="topSticky"?this.props.HeaderData.BrandLogoSticky:this.props.HeaderData.BrandLogo}
                        isSticky={this.state.stickyClass==="topSticky"?true:false}
                        alt="Logo"
                        />
                    </Navbar.Brand>

                    <NavbarToggleCustom
                    isSticky={this.state.stickyClass==="topSticky"?true:false}
                    aria-controls="basic-navbar-nav" />

                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="ml-auto">
                            <ScrollSpy offset={-59} items={this.props.HeaderData.Items} currentClassName="is-current">
                            {
                                this.props.HeaderData.MenuItems.map((menuItem, idx)=>{
                                    return <li>
                                        <AnchorLink offset={55} href={menuItem.Href} 
                                        isSticky={this.state.stickyClass==="topSticky"?true:false}>
                                            {menuItem.Menu}
                                        </AnchorLink>
                                    </li>
                                })
                            }
                            </ScrollSpy>
                        </Nav>
                    </Navbar.Collapse>

                </Container>
            </NavbarCustom>
            
        );
    }
}

const HeadermenuSection = () => (
    <StaticQuery
        query={graphql`
            query{
                hospitalJson {
                    Header{
                        BrandLogo
                        BrandLogoSticky
                        Items
                        MenuItems{
                            Menu
                            Href
                        }
                    }
                }
            }
        `}
        render={(data) => (
            <Headermenu HeaderData={data.hospitalJson.Header}/>
        )}
    />
  )
  export default HeadermenuSection;