import React from 'react';
import {DropdownMenu,DropdownAnchor} from './bookappointment.style';

class Dropdown extends React.Component {
    constructor(props){
        super(props);

        this.state = {
            displayMenu: false,
            speciality:this.props.items[0],
        };

        this.showDropdownMenu = this.showDropdownMenu.bind(this);
        this.hideDropdownMenu = this.hideDropdownMenu.bind(this);
   };

    showDropdownMenu(event) {
        event.preventDefault();
        this.setState({ displayMenu: true }, () => {
        document.addEventListener('click', this.hideDropdownMenu);
        });
    }

    hideDropdownMenu() {
        this.setState({ displayMenu: false }, () => {
            document.removeEventListener('click', this.hideDropdownMenu);
        });
    }

    onDropDownItemClick(itemVal,indexVal)
    {
        this.setState({ speciality: itemVal }, () => {
            this.props.onDropDownItemClick(indexVal)
        });
    }

    render() {
        return (
                <DropdownMenu>
                    <DropdownAnchor onClick={this.showDropdownMenu}>
                        {this.state.speciality}
                    </DropdownAnchor>
                    {
                        this.state.displayMenu ? (
                            <ul>
                                {
                                    this.props.items.length > 0 && this.props.items.map(function(itemVal,indexVal) {
                                        return <li onClick={this.onDropDownItemClick.bind(this,itemVal,indexVal)}>
                                        <a href="javascript:void(0);" >
                                        {itemVal}</a></li>
                                    },this)
                                }
                            </ul>
                        ):
                        (
                            null
                        )
                    }
                </DropdownMenu>
        );
    }
}

export default Dropdown;
