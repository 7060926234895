import React,{Component} from "react";
import Banner from "../containers/Hospital/Banner";
import HeaderMenuBS from "../containers/Hospital/HeaderMenuBS";
import Services from "../containers/Hospital/Services";
import About from "../containers/Hospital/About";
import Stats from "../containers/Hospital/Stats";
import Emergency from "../containers/Hospital/Emergency";
import Specialities from "../containers/Hospital/Specialities";
import OurSpecialists from "../containers/Hospital/OurSpecialists";
import Gallery from "../containers/Hospital/Gallery";
import Testimonials from "../containers/Hospital/Testimonials";
import BookAppointment from "../containers/Hospital/BookAppointment";
import Footer from "../containers/Hospital/Footer";
import GlobalStyle from "../containers/Hospital/Common/global-styles";
import "../components/layout.css";
import Seo from "../components/seo";
import Fonts from "../containers/Hospital/Common/fonts"
import BuyNow from "../components/BuyNow";
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

class Hospital extends Component{
    constructor(props) {
        super(props);
        this.state = {
            isOpenLightBox: false,
            lightBoxImages:[],
            photoIndex: 0,
        }
        this.openLightBox = this.openLightBox.bind(this);
    }


    openLightBox(imageArray,idx)
    {
        console.log(imageArray)
        const images = [];
        for(const [index,value] of imageArray.entries()){
            console.log(value);
            images.push(value.Img.publicURL);
        }
        this.setState(
            {
                isOpenLightBox: true,
                lightBoxImages:images,
                photoIndex:idx
            }
        );
    }


    render() {
        return(
                <div>
                {
                    <div>
                        <Fonts />
                        <GlobalStyle />
                        <BuyNow />
                        <HeaderMenuBS/>
                        <main>
                                <Seo 
                                    title="Hospital ReactJS Landing Template | GatsbyJS Templates | Zircon"
                                    description="Buy Hospital Landing page template from Reactrepo. Template is built with react, gatsbyjs, bootstrap and styled components"
                                />
                                <Banner/>
                                <Services/>
                                <About/>
                                <Stats/>
                                <Specialities/>
                                <OurSpecialists/>
                                <Emergency/>
                                <Gallery  openLightBox = {this.openLightBox}/>
                                <Testimonials/>
                                <BookAppointment/>
                            </main>
                        <Footer/>
                    </div>
                }
                {
                    this.state.isOpenLightBox && (
                    <Lightbox
                        mainSrc={this.state.lightBoxImages[this.state.photoIndex]}
                        nextSrc={this.state.lightBoxImages[(this.state.photoIndex + 1) % this.state.lightBoxImages.length]}
                        prevSrc={this.state.lightBoxImages[(this.state.photoIndex + this.state.lightBoxImages.length - 1) % this.state.lightBoxImages.length]}
                        onCloseRequest={() => this.setState({ isOpenLightBox: false })}
                        onMovePrevRequest={() =>
                        this.setState({
                            photoIndex: (this.state.photoIndex + this.state.lightBoxImages.length - 1) % this.state.lightBoxImages.length,
                        })
                        }
                        onMoveNextRequest={() =>
                        this.setState({
                            photoIndex: (this.state.photoIndex + 1) % this.state.lightBoxImages.length,
                        })
                        }
                    />
                    )
                }   
                </div> 
            )
        }
    }
 
 export default Hospital;
 