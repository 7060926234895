import React,{Component} from 'react';
import {BookAppointmentSection,HeadingLayout,Heading,Border,DateInput,Form,
    FormGroup,NameInput,BookAppointmentSpanErr,BookAppointmentSpanSuccess,
    DropdownFormGroup,BtnFormGroup,CommonFormBtn
} from './bookappointment.style';
import {Container, Row, Col} from 'react-bootstrap'; 
import 'bootstrap/dist/css/bootstrap.min.css';
import { StaticQuery, graphql } from 'gatsby';
import "react-datepicker/dist/react-datepicker.css";
import Dropdown from "./dropdown.js";

const WRNG_MSG_TIMEOUT = 3000;
const itemsObj = ["Select Speciality","Cardiology","Neurology","Gastro Enterology","Dental Care"];

class BookAppointment extends Component{
    constructor(){
        super();
        this.state = {
            errors:[],
            bookAppointment_name:"",
            bookAppointment_email:"",
            bookAppointment_date:"",
            bookAppointment_mobNo:"",
            bookAppointment_speciality:0
        }
        this.onDropDownItemClick = this.onDropDownItemClick.bind(this);
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        this.setState({ [name]: value });
    }

    handleChangeDate = (e) => {
        this.setState({ bookAppointment_date: e });
    }

    onDropDownItemClick(indexVal){
        this.setState(
        {
            bookAppointment_speciality:indexVal
        });
    }

    validateForm()
    {
        var error_flag = false;
        let errors = {};
        if(this.state.bookAppointment_name === "")
        {
            error_flag = true;
            errors['bookAppointment_name'] = "Please enter name!";
            setTimeout(function(){
                this.setState({errors:{}});
           }.bind(this),WRNG_MSG_TIMEOUT);
        }

        if(this.state.bookAppointment_email === "")
        {
            error_flag = true;
            errors['bookAppointment_email'] = "Please enter email!";
            setTimeout(function(){
                this.setState({errors:{}});
           }.bind(this),WRNG_MSG_TIMEOUT);
        }

        if(this.state.bookAppointment_email !== "")
        {
            var patternEmail = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if(!patternEmail.test(this.state.bookAppointment_email)) {
                error_flag = true;
                errors["bookAppointment_email"] = "Please enter valid email ID!";
                setTimeout(function(){
                    this.setState({errors:{}});
               }.bind(this),WRNG_MSG_TIMEOUT);
            }
        }

        if(this.state.bookAppointment_date === "")
        {
            error_flag = true;
            errors["bookAppointment_date"] = "Please enter date!";
            setTimeout(function(){
                this.setState({errors:{}});
           }.bind(this),WRNG_MSG_TIMEOUT);
        }

        if(this.state.bookAppointment_date !== "")
        {
            var newDate = new Date(this.state.bookAppointment_date);
            var newDateY = newDate.getFullYear();
            var newDateM = newDate.getMonth()+1;
            var newDateD = newDate.getDate();
            
            var currentDate = new Date();
            var currentDateY = currentDate.getFullYear();
            var currentDateM = currentDate.getMonth()+1;
            var currentDateD = currentDate.getDate();

            var dateFlag = false;
            if(newDateY !== currentDateY || newDateM !== currentDateM)
            {
                dateFlag = true;
            }
            else if(newDateY === currentDateY && newDateM === currentDateM && newDateD < currentDateD)
            {
                dateFlag = true;
            }
            if(dateFlag)
            {
                error_flag = true;
                errors["bookAppointment_date"] = "Please select a valid booking date!";
                setTimeout(function(){
                    this.setState({errors:{}});
                }.bind(this),WRNG_MSG_TIMEOUT);
            }
        }

        if(this.state.bookAppointment_speciality === 0)
        {
            error_flag = true;
            errors["bookAppointment_speciality"] = "Please select speciality!";
            setTimeout(function(){
                this.setState({errors:{}});
           }.bind(this),WRNG_MSG_TIMEOUT);
        }

        if(this.state.bookAppointment_mobNo === "")
        {
            error_flag = true;
            errors["bookAppointment_mobNo"] = "Please enter mobile no!";
            setTimeout(function(){
                this.setState({errors:{}});
           }.bind(this),WRNG_MSG_TIMEOUT);
        }

        if(this.state.bookAppointment_mobNo !== "")
        {
            var pattern = new RegExp(/^(\+\d{1,3}[- ]?)?\d{10}$/);
            if(!pattern.test(this.state.bookAppointment_mobNo)) {
                error_flag = true;
                errors["bookAppointment_mobNo"] = "Please enter valid mobile no!";
                setTimeout(function(){
                    this.setState({errors:{}});
               }.bind(this),WRNG_MSG_TIMEOUT);
            }
        }

        this.setState({
            errors: errors
        });
        return error_flag;
    }

    bookAppointmentSubmit = (e)=>{
        e.preventDefault();
        if(!this.validateForm())
        {
            //SUCCESS 
            //TO DO
            console.log("Name: "+this.state.bookAppointment_name);
            console.log("Email: "+this.state.bookAppointment_email);
            console.log("Date: "+this.state.bookAppointment_date);
            console.log("Mobile No: "+this.state.bookAppointment_mobNo);
            console.log("Speciality: "+this.state.bookAppointment_speciality);
            document.getElementById("success_bookTable_message").innerHTML = "Appointment fixed successfully.";
            this.setState({
                bookAppointment_name:"",
                bookAppointment_email:"",
                bookAppointment_date:"",
                bookAppointment_mobNo:"",
                bookAppointment_speciality:"",
                errors:[]
            },()=>{
                setTimeout(function(){
                    document.getElementById("success_bookTable_message").innerHTML = "";
               },WRNG_MSG_TIMEOUT);
            });
        }
    }

    render(){
        return(
            <BookAppointmentSection id="bookAppointmentContainer">
                <Container>
                    <HeadingLayout>
                        <Heading>
                        {this.props.hospitalJson.BookAppointment.Heading} 
                        </Heading>
                        <Border src={this.props.hospitalJson.BookAppointment.Border} alt=""/>
                    </HeadingLayout>
                    <Row>
                        <Col md="12">
                                <Form id="bookAppointmentForm" method="post" onSubmit={this.bookAppointmentSubmit}>
                                    <Row>
                                        <Col md="12">
                                            <FormGroup>
                                                <NameInput aria-label="Name" type="text" placeholder="Name" name="bookAppointment_name" onChange={this.handleChange} value={this.state.bookAppointment_name}/>
                                                <BookAppointmentSpanErr>{this.state.errors.bookAppointment_name}</BookAppointmentSpanErr>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12">
                                            <FormGroup>
                                                <NameInput aria-label="Email" type="text" placeholder="Email" name="bookAppointment_email" onChange={this.handleChange} value={this.state.bookAppointment_email}/>
                                                <BookAppointmentSpanErr>{this.state.errors.bookAppointment_email}</BookAppointmentSpanErr>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12">
                                            <FormGroup>
                                                <NameInput aria-label="Mob No." type="tel" placeholder="Mob No." name="bookAppointment_mobNo" onChange={this.handleChange} value={this.state.bookAppointment_mobNo}/>
                                                <BookAppointmentSpanErr>{this.state.errors.bookAppointment_mobNo}</BookAppointmentSpanErr>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="6">
                                            <FormGroup>
                                                <DateInput
                                                    name="bookAppointment_date"
                                                    selected={this.state.bookAppointment_date}
                                                    onChange={this.handleChangeDate}
                                                    dateFormat = "dd-MM-yyyy"
                                                    placeholderText="dd-mm-yyyy"
                                                    ariaLabelledBy="Book Appointment Date"
                                                />
                                                <BookAppointmentSpanErr>{this.state.errors.bookAppointment_date}</BookAppointmentSpanErr>
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <DropdownFormGroup>
                                                <Dropdown 
                                                    items={itemsObj} 
                                                    onDropDownItemClick = {this.onDropDownItemClick} 
                                                />
                                                <br/>
                                                <BookAppointmentSpanErr>{this.state.errors.bookAppointment_speciality}</BookAppointmentSpanErr>
                                            </DropdownFormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col md="12">
                                            <BtnFormGroup>
                                                <CommonFormBtn type="submit">{this.props.hospitalJson.BookAppointment.CommonFormBtn}</CommonFormBtn>
                                            </BtnFormGroup>
                                        </Col>
                                    </Row>
                                    <BookAppointmentSpanSuccess id="success_bookTable_message"></BookAppointmentSpanSuccess>
                                </Form>
                        </Col>
                    </Row>
                </Container>
            </BookAppointmentSection>
        )
    }
}

const BookAppointmentPageSection =  () => (
<StaticQuery
    query={graphql`
    query {
        hospitalJson{
            BookAppointment{
                    Heading
                    Border
                    CommonFormBtn
                }
            }
        }
    `}
    render={(data) => (
        <BookAppointment hospitalJson={data.hospitalJson}/>
    )}
/>
)
export default BookAppointmentPageSection;
