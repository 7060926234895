import {createGlobalStyle} from 'styled-components';
import {device} from './device';

const GlobalStyle = createGlobalStyle`

    body{
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        .ReactModal__Overlay{
            z-index:9999 !important;
        }
    }

    h1,h2,h3,h4,h5,h6{
        font-family: 'Heebo', sans-serif;
        font-weight: 500;
        margin-bottom:20px;
        color:#333333;
    }

    h1{
        font-size:55px;
        line-height:61px;

        @media ${device.laptop} {
            font-size:45px;
            line-height:50px;
        }

        @media ${device.tablet} {
            text-align:center;
        }
        @media ${device.mobileXL} {
            font-size:40px;
            line-height:45px;
        }
    }

    h2{
        font-size:42px;
        line-height:48px;

        @media ${device.tablet} {
            font-size:40px;
            line-height:46px;
        }

        @media ${device.mobileXL} {
            font-size:36px;
            line-height:42px;
        }
    }

    h3{
        font-size:30px;
        line-height:38px;
        font-weight:700;

        @media ${device.tablet} {
            font-size:28px;
            line-height:34px;
        }
    }

    h4{
        font-size:26px;
        line-height:32px;

        @media ${device.laptop} {
            font-size:24px;
            line-height:30px;
        }
    }

    h5{
        font-size:24px;
        line-height:30px;
    }

    h6{
        font-size:22px;
        line-height:28px;

        @media ${device.tablet} {
            text-align:center;
        }

        @media ${device.mobileXL} {
            font-size:17px;
            line-height:23px;
        }
    }

    p{
        font-family: 'Roboto', sans-serif;
        font-weight: 400;
        color:#666666;
        font-size:15px;
        line-height:1.75;
    }

    a{
        font-size:16px;
        font-family: 'Roboto', sans-serif;
        font-weight:500;
        text-decoration:none;
        :hover{
            text-decoration:none;
        }
    }

    img{
        margin-bottom:0px;
    }
    .navbar-expand-lg .navbar-collapse{
        justify-content: right;
    }
`;

export default GlobalStyle;
