import styled from 'styled-components';
import {SectionHeading} from '../Common/common.style';
import {LeftArrowAlt} from '@styled-icons/boxicons-regular/LeftArrowAlt';
import {RightArrowAlt} from '@styled-icons/boxicons-regular/RightArrowAlt';
import {device} from '../Common/device';

export const OurSpecialistsSection = styled.section`
    padding:100px 0px 80px;

    @media ${device.tablet}{
        padding:80px 0px 60px;
    }
`;

export const HeadingLayout = styled.div`
    margin-bottom:40px;
    text-align:center;
`;

export const Heading = styled(SectionHeading)`
    text-align:center;
`;

export const Border = styled.img`

`;

export const SliderOuterLayout = styled.div`
    position:relative;
`;

export const LeftArrowLayout = styled.div`
    border-right:1px solid #1565c0;
    border-bottom:1px solid #1565c0;
    border-top:1px solid #1565c0;
    border-left:1px solid #1565c000;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    position:absolute;
    top:145px;
    z-index:1;

    @media ${device.tablet}{
        top:220px;
    }
`;

export const LeftArrow = styled(LeftArrowAlt)`
    width:40px;
    color:#1565c0;
    margin: 5px 5px 5px 10px;
    cursor:pointer;
`;

export const SpecialistOuterWrapper = styled.div`
    position:relative;
`;

export const SliderInnerLayout = styled.div`
    outline:0;
`;

export const TeamLayout = styled.div`
    margin:20px;
    .TeamProPic{
        height:300px;

        @media ${device.tablet}{
            height:450px;
        }
    }
    @media ${device.tablet}{
        margin:20px 10px;
    }

    h6{
        line-height:1;
        margin-bottom:10px;
        text-align:center;
        margin-top:20px;
    }
    p{
        margin-bottom:0px;
        line-height:1;
        text-align:center;
    }
`;

export const TeamProPic = styled.img`

`;

export const RightArrowLayout = styled.div`
    border-left:1px solid #1565c0;
    border-bottom:1px solid #1565c0;
    border-top:1px solid #1565c0;
    border-right:1px solid #1565c000;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    position:absolute;
    top:145px; // (height of image/2)+(top & bottom margin) - (height of the icon)
    right:0;

    @media ${device.tablet}{
        top:220px;
    }
`;

export const RightArrow = styled(RightArrowAlt)`
    width:40px;
    color:#1565c0;
    margin: 5px 5px 5px 10px;
    cursor:pointer;
`;
