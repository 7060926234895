import styled from 'styled-components';
import {SectionHeading} from '../Common/common.style';
import {device} from '../Common/device';

export const GallerySection = styled.section`

`;
export const GalleryOuterWrapper = styled.div`
    padding:100px 0px 70px;
    border-bottom:1px solid #ebebeb;

    @media ${device.tablet}{
        padding:80px 0px 50px;
    }
`;

export const HeadingLayout = styled.div`
    margin-bottom:60px;
    text-align:center;
`;

export const Heading = styled(SectionHeading)`
    text-align:center;
`;

export const GalleryWrapper = styled.div`

`;

export const ImageHolder = styled.div`
    margin-bottom:30px;

    .galleryImg{
        height:250px;
        cursor:pointer;
    }
`;
