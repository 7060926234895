import React from 'react';
import {BannerSection,BannerContents,BannerContentsLeft,
    WatchVideoAnchor,WatchVideoLayout,
    PlayIcon
} from './banner.style';
import {Container} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useStaticQuery, graphql } from 'gatsby';

const Banner = () => {
    const JSONData  = useStaticQuery(graphql`
        query {
                hospitalJson{
                    Banner{
                        BannerHeading
                        BannerDescription
                        WatchVideoAnchor
                        BtnText
                    }
                }
            }
    `);
        return (
        <BannerSection id="homeContainer">
            <Container>
                <BannerContents>
                    <BannerContentsLeft>
                        <h1>
                        {JSONData.hospitalJson.Banner.BannerHeading}
                        </h1>
                        <p>
                        {JSONData.hospitalJson.Banner.BannerDescription}
                        </p>
                        <WatchVideoAnchor>
                            <WatchVideoLayout>
                                <PlayIcon />
                                <span>
                                    {JSONData.hospitalJson.Banner.BtnText}
                                </span>
                            </WatchVideoLayout>
                        </WatchVideoAnchor>
                    </BannerContentsLeft>
                </BannerContents>
            </Container>
        </BannerSection>
    );
}

export default Banner;
