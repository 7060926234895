import styled from 'styled-components';
import {device} from '../Common/device';
import {SectionHeading,Commonbtn} from '../Common/common.style';
import {Row} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {CheckCircle} from '@styled-icons/boxicons-regular/CheckCircle';

export const AboutSection = styled.section`
    padding:100px 0px;

    @media ${device.tablet} {
        padding:80px 10px;
    }
`;

export const CustomRow = styled(Row)`
    align-items:center;
    @media ${device.laptop} {
        flex-direction:column-reverse;
    }
`;

export const TextLayout = styled.div`
    @media ${device.laptop} {
        margin-bottom:30px;
    }

    ul{
        list-style:none;
        margin:0;
    }
    ul li{
        display:flex;
        margin-bottom: 0px;
    }
    ul li p{
        margin-left:15px;
    }
    p{
        line-height:1;
        margin-bottom:20px;
        line-height:25px;
    }
`;

export const HeadingLayout = styled.div`
    margin-bottom:30px;
`;

export const Heading = styled(SectionHeading)`
    text-align:left;
`;

export const Border = styled.img`

`;


export const CheckCircleIcon = styled(CheckCircle)`
    color:#ef5350;
    height:25px;
    width:25px;
    flex-shrink:0;
    margin-top:5px;
`;



export const ContactUsBtn = styled(Commonbtn)`
    
`;

export const ImageHolder = styled.div`
    padding-right:50px;

    @media ${device.laptop} {
        padding-right:0px;
    }
`;
