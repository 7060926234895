import React from 'react';
import {SpecialitiesSection,SpecialitiesWrapper,SpecialitiesLeft,HeadingLayout,Heading,Border,
    SpecialityLayout,SpecialityTextLayout,
    SpecialitiesRight,
    ImageHolder
} from './specialities.style';
import {Container,Row,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { GatsbyImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from 'gatsby';

const Specialities = () => {
    const JSONData  = useStaticQuery(graphql`{
  hospitalJson {
    Specialities {
      Heading
      Border
      Description
      SpecialitiesData {
        Icon
        SpecialityHeading
        SpecialityDescription
      }
      SpecialityRightImg {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
    }
  }
}
`);
        return (
            <SpecialitiesSection id="specialitiesContainer">
                <Container>
                    <SpecialitiesWrapper>
                        <SpecialitiesLeft>
                            <HeadingLayout>
                                <Heading>
                                {JSONData.hospitalJson.Specialities.Heading}
                                </Heading>
                                <Border src={JSONData.hospitalJson.Specialities.Border}  alt=""/>
                            </HeadingLayout>
                            <p>
                            {JSONData.hospitalJson.Specialities.Description}
                            </p>
                            <Row>
                                <Col md={6}>
                                    <SpecialityLayout>
                                        <img src={JSONData.hospitalJson.Specialities.SpecialitiesData[0].Icon} alt=""/>
                                        <SpecialityTextLayout>
                                        <h6>
                                        {JSONData.hospitalJson.Specialities.SpecialitiesData[0].SpecialityHeading}
                                        </h6>
                                        <p>
                                        {JSONData.hospitalJson.Specialities.SpecialitiesData[0].SpecialityDescription}
                                        </p>
                                        </SpecialityTextLayout>
                                    </SpecialityLayout>
                                </Col>
                                <Col md={6}>
                                    <SpecialityLayout>
                                        <img src={JSONData.hospitalJson.Specialities.SpecialitiesData[1].Icon} alt=""/>
                                        <SpecialityTextLayout>
                                        <h6>
                                        {JSONData.hospitalJson.Specialities.SpecialitiesData[1].SpecialityHeading}
                                        </h6>
                                        <p>
                                        {JSONData.hospitalJson.Specialities.SpecialitiesData[1].SpecialityDescription}
                                        </p>
                                        </SpecialityTextLayout>
                                    </SpecialityLayout>
                                </Col>
                                <Col md={6}>
                                    <SpecialityLayout>
                                        <img src={JSONData.hospitalJson.Specialities.SpecialitiesData[2].Icon} alt=""/>
                                        <SpecialityTextLayout>
                                        <h6>
                                        {JSONData.hospitalJson.Specialities.SpecialitiesData[2].SpecialityHeading}
                                        </h6>
                                        <p>
                                        {JSONData.hospitalJson.Specialities.SpecialitiesData[2].SpecialityDescription}
                                        </p>
                                        </SpecialityTextLayout>
                                    </SpecialityLayout>
                                </Col>
                                <Col md={6}>
                                    <SpecialityLayout>
                                        <img src={JSONData.hospitalJson.Specialities.SpecialitiesData[3].Icon} alt=""/>
                                        <SpecialityTextLayout>
                                        <h6>
                                        {JSONData.hospitalJson.Specialities.SpecialitiesData[3].SpecialityHeading}
                                        </h6>
                                        <p>
                                        {JSONData.hospitalJson.Specialities.SpecialitiesData[3].SpecialityDescription}
                                        </p>
                                        </SpecialityTextLayout>
                                    </SpecialityLayout>
                                </Col>
                                <Col md={6}>
                                    <SpecialityLayout>
                                        <img src={JSONData.hospitalJson.Specialities.SpecialitiesData[4].Icon} alt=""/>
                                        <SpecialityTextLayout>
                                        <h6>
                                        {JSONData.hospitalJson.Specialities.SpecialitiesData[4].SpecialityHeading}
                                        </h6>
                                        <p>
                                        {JSONData.hospitalJson.Specialities.SpecialitiesData[4].SpecialityDescription}
                                        </p>
                                        </SpecialityTextLayout>
                                    </SpecialityLayout>
                                </Col>
                                <Col md={6}>
                                    <SpecialityLayout>
                                        <img src={JSONData.hospitalJson.Specialities.SpecialitiesData[5].Icon} alt=""/>
                                        <SpecialityTextLayout>
                                        <h6>
                                        {JSONData.hospitalJson.Specialities.SpecialitiesData[5].SpecialityHeading}
                                        </h6>
                                        <p>
                                        {JSONData.hospitalJson.Specialities.SpecialitiesData[5].SpecialityDescription}
                                        </p>
                                        </SpecialityTextLayout>
                                    </SpecialityLayout>
                                </Col>
                            </Row>
                        </SpecialitiesLeft>
                        <SpecialitiesRight>
                            <ImageHolder>
                                <GatsbyImage
                                    image={JSONData.hospitalJson.Specialities.SpecialityRightImg.childImageSharp.gatsbyImageData}
                                    alt="" />
                            </ImageHolder>
                        </SpecialitiesRight>
                    </SpecialitiesWrapper>
                </Container>
            </SpecialitiesSection>
        );
}
export default Specialities;
