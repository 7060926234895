import React from 'react';
import {StatsSection,StatsLayout
} from './stats.style';
import {Container,Row,Col} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useStaticQuery, graphql } from 'gatsby';

const Stats = () => {
    const JSONData  = useStaticQuery(graphql`
        query {
                hospitalJson{
                    Stats{
                        StatsData{
                            StatsCount
                            StatsDetails
                        }
                    }
                }
            }
    `);
        return (
        <StatsSection id="StatsContainer">
            <Container>
                <Row>
                    <Col sm={6} md={6} lg={3}>
                        <StatsLayout>
                            <h1>
                            {JSONData.hospitalJson.Stats.StatsData[0].StatsCount}
                            </h1>
                            <p>
                            {JSONData.hospitalJson.Stats.StatsData[0].StatsDetails}
                            </p>
                        </StatsLayout>
                    </Col>
                    <Col sm={6} md={6} lg={3}>
                        <StatsLayout>
                            <h1>
                            {JSONData.hospitalJson.Stats.StatsData[1].StatsCount}
                            </h1>
                            <p>
                            {JSONData.hospitalJson.Stats.StatsData[1].StatsDetails}
                            </p>
                        </StatsLayout>
                    </Col>
                    <Col sm={6} md={6} lg={3}>
                        <StatsLayout>
                            <h1>
                            {JSONData.hospitalJson.Stats.StatsData[2].StatsCount}
                            </h1>
                            <p>
                            {JSONData.hospitalJson.Stats.StatsData[2].StatsDetails}
                            </p>
                        </StatsLayout>
                    </Col>
                    <Col sm={6} md={6} lg={3}>
                        <StatsLayout>
                            <h1>
                            {JSONData.hospitalJson.Stats.StatsData[3].StatsCount}
                            </h1>
                            <p>
                            {JSONData.hospitalJson.Stats.StatsData[3].StatsDetails}
                            </p>
                        </StatsLayout>
                    </Col>
                </Row>


            </Container>
        </StatsSection>
    );
}
export default Stats;
