import React from 'react';
import {ServicesSection,FeaturesWrapper,FeaturesLayout,Img} from './services.style';
import {Row,Col,Container} from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useStaticQuery, graphql } from 'gatsby';

const Services = () => {
    const JSONData  = useStaticQuery(graphql`
        query {
                hospitalJson{
                    Services{
                        ServicesData{
                            Img
                            FeaturesHeading
                            Paragraph
                        }
                    }
                }
            }
    `);
        return (
        <ServicesSection id="servicesContainer">
            <Container>
                <FeaturesWrapper>
                <Row>
                    <Col lg={4}>
                        <FeaturesLayout bg={"#00a651"}>
                            <Img
                                src={JSONData.hospitalJson.Services.ServicesData[0].Img}
                                alt=""
                            />
                            <h3>
                                { JSONData.hospitalJson.Services.ServicesData[0].FeaturesHeading }
                            </h3>
                            <p>
                            { JSONData.hospitalJson.Services.ServicesData[0].Paragraph }
                            </p>
                        </FeaturesLayout>
                    </Col>
                    <Col lg={4}>
                        <FeaturesLayout bg={"#1997b1"}>
                            <Img
                                src={JSONData.hospitalJson.Services.ServicesData[1].Img}
                                alt=""
                            />
                            <h3>
                                { JSONData.hospitalJson.Services.ServicesData[1].FeaturesHeading }
                            </h3>
                            <p>
                            { JSONData.hospitalJson.Services.ServicesData[1].Paragraph }
                            </p>
                        </FeaturesLayout>
                    </Col>
                    <Col lg={4}>
                        <FeaturesLayout bg={"#1565c0"}>
                            <Img
                                src={JSONData.hospitalJson.Services.ServicesData[2].Img}
                                alt=""
                            />
                            <h3>
                                { JSONData.hospitalJson.Services.ServicesData[2].FeaturesHeading }
                            </h3>
                            <p>
                            { JSONData.hospitalJson.Services.ServicesData[2].Paragraph }
                            </p>
                        </FeaturesLayout>
                    </Col>
                </Row>
                </FeaturesWrapper>
            </Container>
        </ServicesSection>
    );
}

export default Services;
