import styled from 'styled-components';
import {device} from '../Common/device';
import {CalendarPlus} from '@styled-icons/boxicons-regular/CalendarPlus';
import BackgroundImg from '../../../assets/hospital-images/banner.jpg';

export const BannerSection = styled.section`
    background-image: url(${BackgroundImg});
    background-repeat:no-repeat;
    background-size:cover;
    background-position-x: center;

    min-height: 100vh;
    padding:160px 0px 100px;
    display:flex; 
    align-items:center;

    @media ${device.laptopM} {
        background-position-x: 70%;
    }
    @media ${device.laptopM} {
        background-position-x: 90%;
    }
    @media ${device.tablet} {
        background-position-x: 70%;
        padding:140px 10px 80px;
    }
`;

export const BannerContents = styled.div`
    display:flex;
    align-items:center;
`;

export const BannerContentsLeft = styled.div`
    width:55%;

    @media ${device.laptop} {
        width:65%;
    }
    @media ${device.tablet} {
        width:100%;
        text-align:center;
    }

    h1{
        color:#fff;
        margin-bottom:25px;
    }
    p{
        color:#fff;
        opacity:0.9;
        margin-bottom:30px;
        font-size:18px;
    }
    span{
        color:#fff;
        text-transform:uppercase;
        margin-left:5px;
    }
`;

export const WatchVideoAnchor = styled.a`
    cursor:pointer;
    transition: all 0.3s ease-in-out;

    :hover{
        transition: all 0.3s ease-in-out;
        transform:scale(1.05);
    }
`;

export const WatchVideoLayout = styled.div`
    display:inline-flex;
    align-items:center;
    background:#00000066;
    padding: 12px 40px;
    border-radius: 30px;

    :hover{
        background:#000000aa;
    }

    @media ${device.mobileXL} {
        padding:5px 20px;
    }

    @media ${device.mobileL} {
        margin-top:20px;
        margin-left:0px;
    }
`;

export const PlayIcon = styled(CalendarPlus)`
    width: 28px;
    height: 28px;
    color:#fff;
`;
