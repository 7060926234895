import styled from 'styled-components';
import {SectionHeading} from '../Common/common.style';
import DatePicker from 'react-datepicker';
import BackgroundImg from '../../../assets/hospital-images/appointment-parallax-banner.jpg';
import {device} from '../Common/device';

export const BookAppointmentSection = styled.section`
    background-image: url(${BackgroundImg});
    background-repeat:no-repeat;
    background-size:cover;
    background-position: center;
    padding:100px 0px;

    @media ${device.tablet}{
        padding:80px 10px;
    }
`;

export const HeadingLayout = styled.div`
    margin-bottom:40px;
    text-align:center;
`;

export const Heading = styled(SectionHeading)`
    text-align:center;
    color:#fff;
`;

export const Border = styled.img`

`;

export const Form = styled.form`
    margin-bottom:0px;
    max-width:700px;
    margin:auto;
`;

export const FormGroup = styled.div`
    margin-bottom:20px;

    .react-datepicker-wrapper{
        width:100%;
    }
`;

export const DropdownMenu = styled.div`
    position: relative;
    display: inline-block;
    cursor: pointer;
    width: 100%;

    ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        width:100%;
        top:50px;
        border-radius: 4px;
        background-color: white;
        position: absolute;
        box-sizing: border-box;
        box-shadow: rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.31) 0px 0px 1px;
        z-index: 1;
    }

    li a {
        color: #333;
        text-decoration: none;
    }

    li {
        padding: 8px 16px;
        cursor: pointer;
        margin:0;
        /* border-bottom: 1px solid #e5e5e5; */
    }

    li:last-child {
        border-bottom: none;
    }

    li:hover {
        background-color: #e5e5e5;
        color: white;
    }

`;

export const DropdownFormGroup = styled.div`
    width:100%;
    margin-bottom:20px;
`;

export const DropdownAnchor = styled.a`
    width: 100%;
    display:flex;
    outline:0;
    background:#f7f7f7;
    padding: 10px 20px;
    border: none;
    border-radius:2px;
    font-size:14px;
`;

export const NameInput = styled.input`
    width: 100%;
    outline:0;
    background:#f7f7f7;
    padding: 10px 20px;
    border: none;
    border-radius:2px;
    font-size:14px;
    ::placeholder
    {
        font-size:14px;
    }
`;

export const DateInput = styled(DatePicker)`
    width: 100%;
    outline:0;
    border-radius:2px;
    padding: 10px 20px;
    background:#f7f7f7;
    font-size:14px;
    border: none;   
    ::placeholder{
        font-size:14px;
    }
`;

export const BookAppointmentSpanErr = styled.span`
    color:red;
    font-size:14px;
`;

export const BookAppointmentSpanSuccess = styled.span`
    color:green;
    font-size:14px;
`;

export const BtnFormGroup = styled.div`
    text-align:center;
`;

export const CommonFormBtn = styled.button`
    border:2px solid #fff;
    background:#ffffff33;
    color: #fff;
    padding: 8px 40px;
    text-decoration:none;
    border-radius: 20px;
    display:inline-block;
    text-transform:uppercase;
    cursor:pointer;

    :hover{
        background:#000000cc;
    }

`;


